<template>
  <div class="offlineJoinDIV">
    <div class="offlineJoinContent">
      <div class="topImgBox">
        <img src="@/assets/img/antiFGCheck.png">
      </div>
      <!-- <div class="flexRowStart"> -->
      <div class="flexColumnStart">
        <p v-if="TFFlag == '0'" class="falseC">{{codeMsg}}</p>
        <p v-if="TFFlag == '1'" class="trueC">{{codeMsg}}</p>
        <el-input placeholder="请输入内容" class="inputFBx" v-model="inputCode">
          <el-button slot="append" @click="doCheck()">验证</el-button>
        </el-input>
        <span v-if="TFFlag == '1'" class="checkFirstC">您是第<span class="redTx">{{countNum}}</span>次查询，首次查询时间为：<span class="redTx">{{firstTim}}</span></span>
        <div class="stepFWBx">
          <span class="textSC01">简单三步防伪查询</span>
          <span class="textSC02">1、请刮开或揭开涂层，获得位防伪码，将防伪码从左到右，由上到下依次输入到上方的框中，点击“验证”，您就能获得该产品的真伪信息。</span>
          <span class="textSC02">2、通过验证防伪码正确，系统将提示“您所查询的是xx公司的正牌产品，请放心使用”；通过验证防伪码错误，系统将提示“您输入的防伪码不正确，请仔细检查，谨防假冒”。</span>
          <span class="textSC02 marginBtm160">3、请核对标签上防伪码的颜色与查询结果是否一致。</span>
        </div>
        <!-- <img class="stepBC01" src="@/assets/img/antiForgeryCheck1.png">
        <img class="stepBC02" src="@/assets/img/antiForgeryCheck2.png"> -->
      </div>
    </div>
  </div>
</template>
<script>
import { doCheckCode } from "../util/api"
export default {
  name: 'index',
  components: {},
  data() {
    return {
      // 0--假货、1--真货、其他任意-不展示文字
      TFFlag: '2',
      inputCode: '',
      codeMsg: '',
      countNum: 0,
      firstTim: ''
    }
  },
  created() {},
  methods: {
    doCheck() {
      if(this.inputCode.length > 0) {
        doCheckCode({
          'code': this.inputCode
        }).then((res)=>{
          this.TFFlag = res.code;
          this.codeMsg = res.msg;
          if(res.code == "1") {
            this.countNum = res.data.search_count;
            this.firstTim = res.data.first_time;
          }
        })
      } else {
        this.codeMsg = ""
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/font/font.css";
.offlineJoinDIV {
  display: flex;
  flex-direction: column;
  align-items: center;
  .offlineJoinContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .topImgBox {
      width: 1200px;
      display: flex;
      justify-content: center;
      img {
        margin: 70px 0;
        width: 432px;
        height: 80px;
      }
    }
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      align-items: center;
      .falseC {
        font-size: 30px;
        font-family: Light;
        color: #FF0000;
      }
      .trueC {
        color: #4ab98d;
        font-size: 30px;
        font-family: Light;
        // color: #323232;
      }
      .el-input-group {
        width: 80% !important;
      }
      .inputFBx {
        height: 56px;
      }
      .checkFirstC {
        // width: 440px;
        // height: 17px;
        margin-top: 18px;
        font-size: 25px;
        font-family: Light;
        // font-weight: normal;
        color: #858585;
        // line-height: 296px;
        .redTx {
          color: #f00;
        }
      }
      .stepFWBx {
        width: 1170px;
        display: flex;
        flex-direction: column;
        .textSC01 {
          margin-top: 80px;
          font-size: 34px;
          font-family: Light;
          color: #323232;
        }
        .textSC02 {
          margin-top: 20px;
          font-size: 26px;
          font-family: Light;
          font-weight: 300;
          color: #323232;
        }
        // .marginBtm160 {
        //   margin-bottom: 20px;
        // }
      }
    }
    .flexRowStart {
      display: flex;
      // flex-direction: column;
      align-items: flex-end;
      margin-bottom: 100px;
      .stepBC01 {
        width: 492px;
        height: 270px;
      }
      .stepBC02 {
        margin-left: 140px;
        width: 486px;
        height: 366px;
      }
    }
  }
}
</style>

<style lang="less">
.flexColumnStart {
  .el-input-group {
    .el-input__inner {
      height: 56px;
      font-size: 34px;
    }
    .el-input-group__append {
      background: #05aa8c;
      width: 100px;
      text-align: center;
      color: #fff;
      font-size: 34px;
      font-family: Light;
    }
  }
}
</style>
