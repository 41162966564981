<template>
  <div class="historyBC">
    <div class="offlineJoinContentHisBBC">
      <div class="topImgBox">
        <img src="@/assets/img/history/ljts.png" @click="jumpdown01(300)" />
      </div>
      <div class="flexColumnStart">
        <img src="@/assets/img/joinUs/juContent.png" />
        <img class="HST_lxwm" src="@/assets/img/offlineJoin/lxwm.png" @click="jumpdown02()">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    jumpdown01(val) {
      document.body.scrollTop = document.documentElement.scrollTop = val
    },
    jumpdown02() {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }
}
</script>
<style lang="less" scoped>
.historyBC {
  .offlineJoinContentHisBBC {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #FFF;
    .topImgBox {
      // width: 1920px;
      width: 100%;
      height: 800px;
      background: url('../assets/img/joinUs/joinUSBanner.png');
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-top: 300px;
        margin-left: -550px;
        width: 626px;
        height: 154px;
      }
    }
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      p {
        margin-top: 50px;
        .span01 {
          // width: 200px;
          // height: 50px;
          font-size: 54px;
          font-family: Medium;
          // font-weight: 600;
          color: #323232;
          // line-height: 444px;
        }
        .span02 {
          // width: 307px;
          // height: 34px;
          margin-left: 20px;
          font-size: 44px;
          font-family: Regular;
          font-weight: 100;
          color: #05aa8c;
          // line-height: 292px;
          opacity: 0.35;
        }
      }
      img {
        margin: 40px auto;
        width: 1200px;
      }
      .HST_lxwm {
        width: 626px;
        height: 154px;
      }
    }
  }
}
</style>
