<template>
  <div class="offlineJoinDIVWOM0428">
    <div class="offlineJoinContentWOM0428">
      <div class="flexColumnStart">
        <div class="cpqlFlex">
          <!-- <img class="WOMStarTipImg" src="@/assets/img/WOMStarTip.png" /> -->
          <span class="tilCla01">臻享心得</span>
          <span class="tilCla02">FEELINGS AND EXPERIENCES</span>
        </div>
        <div class="WOMListBoc">
          <div class="listItemB" v-for="(item, index) in WOMStarListC" :key="index+'md'" @click="goDetail(item.praise_id)">
            <img class="STIImg" :src="item.logo_img" />
            <div class="wordTy01">{{item.title}}</div>
            <div class="wordTy02">{{item.brief}}</div>
            <div class="womItmBtnC">点击查看 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWOMStarList } from "../util/api"
export default {
  name: 'index',
  components: {},
  data () {
    return {
      WOMStarListC: []
    }
  },
  created () {
    getWOMStarList({}).then((res)=>{
      this.WOMStarListC = res.data
    })
  },
  methods: {
    goDetail(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({ path: '/WOMStarDetail', query: { pId: val }});
      // if(!sessionStorage.getItem("lastLink") || sessionStorage.getItem("lastLink") != '/WOMStarDetail') {
      //   sessionStorage.setItem("lastLink", '/WOMStarDetail');
      //   document.body.scrollTop = document.documentElement.scrollTop = 0;
      //   this.$router.push({ path: '/WOMStarDetail', query: { pId: val }});
      // } else {
      //   document.body.scrollTop = document.documentElement.scrollTop = 0;
      // }
    },
  }
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
.offlineJoinDIVWOM0428 {
  display: flex;
  flex-direction: column;
  align-items: center;
  .offlineJoinContentWOM0428 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      .cpqlFlex {
        width: 1200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .WOMStarTipImg {
          margin-top: 80px;
          margin-bottom: 40px;
          width: 208px;
          height: 66px;
        }
        .tilCla01 {
          margin-top: 80px;
          margin-bottom: 0px;
          font-size: 46px;
          font-family: Light;
          font-weight: normal;
          color: #323232;
        }
        .tilCla02 {
          margin-bottom: 30px;
          font-size: 20px;
          font-family: Swis721 Th BT;
          font-weight: 100;
          color: #05aa8c;
        }
      }
      .WOMListBoc {
        margin-bottom: 50px;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        .listItemB {
          width: 384px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 30px;
          cursor: pointer;
          margin-right: 16px;
          .STIImg {
            border-radius: 10px;
            margin-bottom: 30px;
            width: 384px;
            height: 488px;
          }
          .wordTy01 {
            height: 68px;
            line-height: 34px;
            font-size: 28px;
            font-family: Light;
            color: #323232;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: flex-start;
            overflow: hidden;
          }
          .wordTy02 {
            height: 48px;
            line-height: 24px;
            margin-bottom: 20px;
            font-size: 20px;
            align-self: flex-start;
            font-family: Light;
            color: #8f969e;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .womItmBtnC {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #05aa8c;
            color: #fff;
            font-size: 20px;
            width: 162px;
            height: 44px;
            line-height: 42px;
            border-radius: 22px;
          }
          .detailBtnC {
            margin-bottom: 38px;
            width: 138px;
            height: 36px;
          }
        }
      }
    }
  }
}
</style>
