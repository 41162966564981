/**   
 * api接口统一管理
 */
import { get, post } from './http'

// 获取全部产品分类
export const getProductList = p => get('/listproduct', p)

// 获取产品详情
export const getProductDetail = p => get('/detailproduct', p)

// 获取新闻列表
export const getNewsList = p => get('/listnews', p)

// 获取新闻详情
export const getNewsDetail = p => get('/detailnews', p)

// 获取明星达人列表
export const getWOMStarList = p => get('/listpraise', p)

// 获取明星达人详情
export const getWOMStarDetail = p => get('/detailpraise', p)

// 防伪验证
export const doCheckCode = p => post('/query', p)

// 首页产品列表
export const getIndexPrdList = p => get('/indexproduct', p)

// 首页Banner图查询
export const getIndexADImgList = p => get('/listad', p)