<template>
  <div class="topBarDBC" @mouseleave="narBrShowF = ''">
    <div class="topBarC">
      <img style="cursor: pointer" src="@/assets/img/logo_G.png" @mouseenter="narBrShowF = 'idx'" @click="goToPage('idx')">
      <div style="cursor: pointer" :class="(narBrBC=='productOverview')?'greenBtc':''" @mouseenter="narBrShowF = 'pdOverView'" :style="`color:${(narBrShowF=='pdOverView')?'#05AA8C':''}`" @click="goToPage('productOverview')">全线系列</div>
      <div style="cursor: pointer" :class="(narBrBC=='history')?'greenBtc':''" @mouseenter="narBrShowF = 'history'" :style="`color:${(narBrShowF=='history')?'#05AA8C':''}`" @click="goToPage('history')">品牌介绍</div>
      <div style="cursor: pointer" :class="(narBrBC=='brandMsgList')?'greenBtc':''" @mouseenter="narBrShowF = 'brandMsgList'" :style="`color:${(narBrShowF=='brandMsgList')?'#05AA8C':''}`" @click="goToPage('brandMsgList')">品牌资讯</div>
      <div style="cursor: pointer" :class="(narBrBC=='WOMStarList')?'greenBtc':''" @mouseenter="narBrShowF = 'WOMNber'" :style="`color:${(narBrShowF=='WOMNber')?'#05AA8C':''}`" @click="goToPage('WOMStarList')">臻享心得</div>
      <div style="cursor: pointer" :class="(narBrBC=='offlineJoin')?'greenBtc':''" @mouseenter="narBrShowF = 'offLJ'" :style="`color:${(narBrShowF=='offLJ')?'#05AA8C':''}`" @click="goToPage('offlineJoin')">代理加盟</div>
      <div style="cursor: pointer" :class="(narBrBC=='antiForgeryCheck')?'greenBtc':''" @mouseenter="narBrShowF = 'antiC'" :style="`color:${(narBrShowF=='antiC')?'#05AA8C':''}`" @click="goToPage('antiForgeryCheck')">防伪查询</div>
      <div style="cursor: pointer" :class="(narBrBC=='joinUs')?'greenBtc':''" @mouseenter="narBrShowF = 'jonUs'" :style="`color:${(narBrShowF=='jonUs')?'#05AA8C':''}`" @click="goToPage('joinUs')">加入我们</div>
      <div style="cursor: pointer" :class="(narBrBC=='contactUs')?'greenBtc':''" @mouseenter="narBrShowF = 'contUs'" :style="`color:${(narBrShowF=='contUs')?'#05AA8C':''}`" @click="goToPage('contactUs')">联系我们</div>
    </div>
    <div class="hidNavBarBC" @mouseleave="narBrShowF = ''">
      <!-- <div class="navBarItmBC" v-show="narBrShowF == 'pdOverView'">
        <span @click="goToPage('productOverview')">洁面系列</span>
        <span @click="goToPage('productOverview')">护肤系列</span>
        <span @click="goToPage('productOverview')">洗护系列</span>
        <span @click="goToPage('productOverview')">底妆系列</span>
      </div> -->
      <!-- <div class="navBarItmBC" v-show="narBrShowF == 'pdMsg'">
        <span style="cursor: pointer" @click="goToPage('history')">品牌发展历程</span>
        <span style="cursor: pointer" @click="goToPage('brandMsgList')">品牌资讯</span>
      </div> -->
      <!-- <div class="navBarItmBC" v-show="narBrShowF == 'WOMNber'">
        <span @click="goToPage('WOMStarList')">明星达人</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'topBar',
  data() {
    return {
      narBrShowF: "",
      narBrBC: ""
    }
  },
  methods: {
    goToPage(val) {
      this.narBrBC = val
      if(val == 'idx') {
        this.$router.push("/");
      } else{
        this.$router.push("/" + val)
      }
    },
  }
}
</script>

<style scoped lang="less">
.topBarDBC {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .topBarC {
    width: 1500px;
    height: 90px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      width: 96px;
      height: 42px;
    }
    div {
      margin-left: 86px;
      font-size: 20px;
      font-family: Light;
      color: #323232;
    }
    .greenBtc {
      color: #05AA8C;
    }
  }
  .hidNavBarBC {
    position: absolute;
    top: 90px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #f4f4f4;
    opacity: 0.6;
    z-index: 999;
    .navBarItmBC {
      width: 1200px;
      display: flex;
      flex-direction: column;
      span {
        // width: 68px;
        height: 60px;
        font-size: 18px;
        font-family: Light;
        color: #000;
        line-height: 60px;
        cursor: pointer;
      }
    }
  }
}
</style>
