<template>
  <div class="offlineJoinDIVPRDDE">
    <div class="offlineJoinContentPRDDE">
      <div class="topImgBox" :style="`background:url(${productObj.logo_img}) no-repeat`"></div>
      <div class="pddBRigC">
        <span class="efficacyC">{{productObj.effect_cn}}</span>
        <span class="capacityC">{{productObj.product_name}}</span>
        <div class="flexColumnStart">
          <div class="textBoxCC">
            <div class="articleBoxC">{{productObj.brief}}</div>
          </div>
        </div>
        <span class="priceC"><span class="minTxt">¥</span>{{productObj.price}}<span class="minTxt">起</span></span>
        <img class="btnBuyC" src="@/assets/img/clickBuy.png" @click="goToBuy(productObj.buy_url)">
      </div>
    </div>
  </div>
</template>
<script>
import { getProductDetail } from "../util/api"
export default {
  name: 'index',
  data() {
    return {
      productObj: {}
    }
  },
  created() {
    getProductDetail(this.$route.query.pId).then((res)=>{
      this.productObj = res.data
    })
  },
  methods: {
    goToBuy(val) {
      window.open(val)
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
.offlineJoinDIVPRDDE {
  display: flex;
  flex-direction: column;
  align-items: center;
  .offlineJoinContentPRDDE {
    display: flex;
    // flex-direction: column;
    align-items: center;
    .topImgBox {
      width: 500px;
      height: 500px;
      // background-image: url('../assets/img/offlineJoin/jm01.png');
      // background-size: 100% 100% !important;
      // background-size: auto 100% !important;
      background-size: contain !important;
      // background-size: cover !important;
      background-position:center center !important;
      display: flex;
      justify-content: center;
    }
    .pddBRigC {
      display: flex;
      flex-direction: column;
      align-items: center;
      .efficacyC {
        width: 626px;
        margin-top: 128px;
        margin-bottom: 16px;
        font-size: 42px;
        font-family: Light;
        text-align: center;
        color: #323232;
      }
      .capacityC {
        width: 626px;
        text-align: center;
        font-size: 26px;
        font-family: Light;
        color: #8F969E;
      }
      .priceC {
        width: 626px;
        text-align: center;
        margin-bottom: 14px;
        font-size: 35px;
        font-family: Swis721 BT;
        font-weight: 600;
        color: #323232;
        .minTxt {
          font-size: 24px;
          font-weight: normal;
        }
      }
      .btnBuyC {
        margin-bottom: 50px;
        width: 400px;
        height: 76px;
        cursor: pointer;
      }
      .flexColumnStart {
        width: 750px;
        // background: #EFEFEF;
        display: flex;
        flex-direction: column;
        .textBoxCC {
          margin: 30px 40px;
          background: #fff;
          border-radius: 10px;
          .articleBoxC {
            margin: 62px 40px;
            font-size: 16px;
            font-family: Light;
            color: #323232;
          }
        }
      }
    }
  }
}
</style>
