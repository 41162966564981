import axios from 'axios' // 引入axios

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.timeout = 80000

// axios.defaults.baseURL = 'http://10.10.0.222/website';
// axios.defaults.baseURL = 'http://snefe.cn/website/';
axios.defaults.baseURL = 'https://www.snefe.com/website/';

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    // axios.get(url, {
    //   params: params
    // }).then(res => {
    //   resolve(res.data)
    // }).catch(err => {
    //   reject(err.data)
    // })

    if(JSON.stringify(params) != '{}') {
      axios.get(url + '/' + params).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err.data)
      })
    }else{
      axios.get(url, {
        params: params
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err.data)
      })
    }
  })
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}