<template>
  <div class="btmBarC">
    <div class="subBxC">
      <img class="logoWCC" src="@/assets/img/logo_W.png">
      <div class="brandIntroductionC">对于雪玲妃而言，甄选珍稀的天然植物成分是给每一位女性的“美丽”礼物，运用现代化科技赋予她们的肌肤健康焕活鲜肌，自信耀眼，使每一位女性更加美丽自信！</div>
      <div class="ltmLogoBC">
        <img src="@/assets/img/tianmao.png" @click="goTianMao()">
        <img src="@/assets/img/jingdong.png" @click="goJingDong()">
        <img src="@/assets/img/weixin.png" @click="goWeChat()">
        <img src="@/assets/img/weibo.png" @click="goWeiBo()">
      </div>
      <div class="linkBarBC">
        <!-- <div class="linkCBBC">
          <p class="wordN01">全线系列</p>
            <p class="wordN02" @click="goToPage('productOverview')">洁面系列</p>
            <p class="wordN02" @click="goToPage('productOverview')">护肤系列</p>
            <p class="wordN02" @click="goToPage('productOverview')">洗护系列</p>
            <p class="wordN02" @click="goToPage('productOverview')">底妆系列</p>
        </div> -->
        <div class="linkCBBC">
          <p class="wordN01" @click="goToPage('history')">品牌介绍</p>
            <!-- <p class="wordN02" @click="goToPage('history')">品牌发展历程</p>
            <p class="wordN02" @click="goToPage('brandMsgList')">品牌资讯</p> -->
        </div>
        <div class="linkCBBC">
          <!-- <p class="wordN01">品牌探索</p>
            <p class="wordN02" @click="goToPage('history')">品牌发展历程</p> -->
            <p class="wordN01" @click="goToPage('brandMsgList')">品牌资讯</p>
        </div>
        <!-- <div class="linkCBBC">
          <p class="wordN01" @click="goToPage('WOMStarList')">臻享心得</p>
            <p class="wordN02" @click="goToPage('WOMStarList')">明星达人</p>
        </div> -->
        <div class="linkCBBC">
          <p class="wordN01" style="cursor: pointer" @click="goToPage('offlineJoin')">代理加盟</p>
        </div>
        <div class="linkCBBC">
          <p class="wordN01" style="cursor: pointer" @click="goToPage('antiForgeryCheck')">防伪查询</p>
        </div>
        <div class="linkCBBC">
          <p class="wordN01" style="cursor: pointer" @click="goToPage('joinUs')">加入我们</p>
        </div>
        <div class="linkCBBC">
          <p class="wordN01" style="cursor: pointer" @click="goToPage('contactUs')">联系我们</p>
        </div>
      </div>
      <div class="copyright">
        <span>©2015-{{new Date().getFullYear()}} 江西初荟化妆品有限公司 版权所有，并保留所有权利</span>
        <span style="margin-left:30px">赣ICP备20006796号-1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'btmBar',
  methods: {
    goToPage(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push("/" + val);
    },
    goTianMao() {
      window.open('https://xuelingfei.tmall.com/?spm=a1z10.3-b-s.1997427721.d4918089.11207d65OfsD3O')
    },
    goJingDong() {
      window.open('https://mall.jd.com/index-149327.html')
    },
    goWeChat() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push("/QRcodeOfOfficialAccount");
    },
    goWeiBo() {
      window.open('https://weibo.com/xuelinfe?is_hot=1')
    },
  }
}
</script>

<style scoped lang="less">
.btmBarC {
  /*background: #0ea9ba;*/
  background: #05aa8c;
  color: #f00;
  display: flex;
  justify-content: center;
  align-items: center;
  .subBxC {
    width: 1400px;
    .logoWCC {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 108px;
      height: 46px;
    }
    .brandIntroductionC {
      width: 1184px;
      font-size: 18px;
      font-family: Light;
      color: #FFFFFF;
      // line-height: 25px;
    }
    .ltmLogoBC {
      margin: 42px auto;
      img {
        margin-right: 20px;
        width: 52px;
        height: 52px;
        cursor: pointer;
      }
    }
    .linkBarBC {
      display: flex;
      justify-content: space-between;
      .linkCBBC {
        width: 240px;
        .wordN01{
          font-size: 22px;
          font-family: Light;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
        }
        .wordN02{
          font-size: 14px;
          font-family: Light;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
    .copyright {
      margin: 10px auto;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      // height: 36px;
      font-size: 14px;
      font-family: Light;
      color: #FFFFFF;
    }
  }
}
</style>
