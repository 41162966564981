<template>
  <div class="contactUs">
    <div class="contactUSCont">
      <img class="titleC" src="@/assets/img/contactUS/contactUSTitle.png">
      <div class="conBoxC marBtmLine">
        <div class="conTItemBC">
          <img class="contentImgC" src="@/assets/img/contactUS/contactUS01.png">
          <div class="contentBBC">
            <div class="contentDetailC">
              <div class="cbox"></div>
              <div class="msgBC">
                雪玲妃（SNEFE）品牌全线升级。在原有口碑产品基础上，雪玲妃加大新品研发，遵循“安全无添加、天然无污染、有效无负担”品牌信条，开创属于自己的健康护肤之路，让消费者由内而外重新焕发肌肤新姿态。深度贯彻品牌使命 — 为世界创造伟大的产品。
              </div>
            </div>
          </div>
        </div>
        <div class="conTItemBC">
          <img class="contentImgC" src="@/assets/img/contactUS/contactUS02.png">
          <div class="contentBBC">
            <div class="contentDetailC">
              <div class="cbox"></div>
              <div class="msgBC">
                投诉举报范围（包含但不限于以下情形）：1）经营管理中违反公司制度、监管政策、相关法律法规的行为；2）公司采购类、公关类管理费用等方面存在违反公司规定的行为；3）对公司内部员工从事重大经济违法行为或违规行为的检举；4）对各部门是否按照相关奖励类文件办事的检举；5）对公司带来经济、声誉损失的行为；6）其他违反公司《反腐败条例》的行为。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="conBoxC">
        <div class="conTItemBC">
          <img class="contentImgC" src="@/assets/img/contactUS/contactUS03.png">
          <div class="contentBBC">
            <div class="contentDetailC">
              <div class="cbox"></div>
              <div class="msgBC">
                雪玲妃品牌创立于上海，10年来我们一直在不断革新，致力于创造更健康护肤的产品，如对我们有任何建议、意见请联系总裁邮箱，您的意见将会成为雪玲妃宝贵的财富。
              </div>
            </div>
          </div>
        </div>
        <div class="conTItemBC">
          <!-- <img class="contentImgC" src="@/assets/img/contactUS/contactUS04.png"> -->
          <div class="twoweicodeBC">
            <!-- <img class="QRCodeC" src="@/assets/img/contactUS/interviewI.png"> -->
            <img class="QRCodeC" src="@/assets/img/QRcodeOfOfficialAccount.png">
          </div>
          <div class="contentBBC">
            <div class="contentDetailC">
              <div class="cbox"></div>
              <div class="msgBC">
                公司是一家集研发、生产、销售于一体的综合性化妆品企业。2012年创办自主品牌“雪玲妃”，现因公司业务发展需要，现面向社会诚聘大量优秀人才。愿您的加入给我们带来新的活力，我们也将为您提供广阔的发展空间!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  components: {}
}
</script>
<style lang="less" scoped>
@import "../assets/font/font.css";
.contactUs {
  .contactUSCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titleC {
      margin-top: 60px;
      margin-bottom: 50px;
      width: 274px;
      height: 78px;
    }
    .marBtmLine {
      border-bottom: 4px solid #eaeaea;
      margin-bottom: 20px;
    }
    .conBoxC {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .conTItemBC {
        width: 580px;
        .contentImgC {
          width: 580px;
          height: 400px;
        }
        .twoweicodeBC {
          width: 580px;
          height: 400px;
          background-image: url('../assets/img/contactUS/contactUS04.png');
          background-size: 100% 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .QRCodeC {
            width: 190px;
            height: 190px;
            // position: absolute;
            // top: 2200px;
            // right: 200px;
            margin-right: 100px;
          }
        }
        .contentBBC {
          border-bottom: 2px solid #EAEAEA;
          margin-bottom: 20px;
          width: 580px;
          .contentDetailC {
            margin: 20px auto 40px auto;
            width: 580px;
            display: flex;
            // align-items: center;
            justify-content: space-around;
            .cbox{
              margin-top: 6px;
              width: 14px;
              height: 14px;
              background: #05aa8c;
            }
            .msgBC{
              width: 560px;
              font-size: 18px;
              font-family: Light;
              color: #323232;
              word-wrap:break-word;
            }
          }
        }
        .contentBBC:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
