<template>
  <div class="offlineJoinDIV">
    <div class="dlCode">
      <div :class="showDlCode?'dm1':'dm2'">
        <p>供销平台</p>
        <img src="../assets/img/dlcode.png" alt="" class="img1">
        <P>Android下载</P>
      </div>
      <img src="../assets/img/more.png" alt="" class="img2" @click="showDlCode = !showDlCode" :class="showDlCode?'':'img3'">
    </div>
    <div class="offlineJoinContent" style="background-color: #f0f9fa;">
      <div class="topImgBox">
        <!-- <img class="logoWCC" src="@/assets/img/logo_W.png" />
        <div class="flexClumAlignC">
          <span class="txt03">ALL-CHANNEL HOT INVESTMENT</span>
          <span class="txt01">线上线下全渠道</span>
          <span class="txt01">火热招商</span>
        </div> -->
      </div>
      <div class="flexColumnStart">
        <div class="flex01">
          <img class="w422h78" src="@/assets/img/offlineJoin/jm02.png">
          <div class="flexColumnCenter">
            <div class="dxjs">
              <img class="imgTop" src="@/assets/img/offlineJoin/jm201.png">
              <img class="imgText" src="@/assets/img/offlineJoin/jm2010.png">
            </div>
            <div class="dxjs">
              <img class="imgTop" src="@/assets/img/offlineJoin/jm202.png">
              <img class="imgText" src="@/assets/img/offlineJoin/jm2020.png">
            </div>
          </div>
        </div>
        <div class="flex02">
          <img class="w440h150" src="@/assets/img/offlineJoin/jm03.png">
          <div class="flexRowAuto">
            <div class="flexColumnCenter02">
              <img src="@/assets/img/offlineJoin/jm03_xlf.png">
              <span class="linkNN">雪玲妃</span>
            </div>
            <div class="flexColumnCenter02">
              <img src="@/assets/img/offlineJoin/jm03_ks.png">
              <span class="linkNN">快手</span>
            </div>
            <div class="flexColumnCenter02">
              <img src="@/assets/img/offlineJoin/jm03_dy.png">
              <span class="linkNN">抖音</span>
            </div>
            <div class="flexColumnCenter02">
              <img src="@/assets/img/offlineJoin/jm03_xg.png">
              <span class="linkNN">西瓜</span>
            </div>
          </div>
        </div>
        <div class="flex02">
          <img class="w440h150" src="@/assets/img/offlineJoin/jm04.png">
          <div class="flexRowAuto02">
            <img class="imgW180H180" src="@/assets/img/offlineJoin/jm401.png">
            <img class="imgW180H180" src="@/assets/img/offlineJoin/jm402.png">
            <img class="imgW180H180" src="@/assets/img/offlineJoin/jm403.png">
            <img class="imgW180H180" src="@/assets/img/offlineJoin/jm404.png">
          </div>
        </div>
        <div class="flex03">
          <img class="w456h150" src="@/assets/img/offlineJoin/jm05.png">
          <div class="flexRowAuto03">
            <div class="dxjs">
              <div class="QRCodeSizeT">
                <img src="@/assets/img/tel.png">
                <span>191-7007-3280</span>
                <span style="color:#000">（电商代理）</span>
              </div>
              <img class="marginTop12" src="@/assets/img/offlineJoin/jm501.png">
            </div>
            <div class="dxjs">
              <div class="QRCodeSizeT">
                <img src="@/assets/img/tel.png">
                <span>181-7289-0520</span>
                <span style="color:#000">（网红主播）</span>
              </div>
              <img class="marginTop12" src="@/assets/img/offlineJoin/jm502.png">
            </div>
            <!-- <div class="dxjs">
              <div class="QRCodeSizeT">
                <img src="@/assets/img/tel.png">
                <span>180-7011-7376</span>
                <span style="color:#000">（社区团购）</span>
              </div>
              <img class="marginTop12" src="@/assets/img/offlineJoin/jm503.png">
            </div>
            <div class="dxjs">
              <div class="QRCodeSizeT">
                <img src="@/assets/img/tel.png">
                <span>193-7911-9725</span>
                <span style="color:#000">（线下商超）</span>
              </div>
              <img class="marginTop12" src="@/assets/img/offlineJoin/jm504.png">
            </div> -->
          </div>
        </div>
        <img class="HST_lxwm" src="@/assets/img/offlineJoin/lxwm.png" @click="jumpdown02()">
      </div>
    </div>
    <div class="offlineJoinContent">
      <div class="flexColumnStart">
        <div class="flex02">
          <img class="w942h210" src="@/assets/img/offlineJoin/p02/jmstd00.png">
          <div class="flexRowAuto">
            <div class="flexColumnCenter02">
              <img class="sty001" src="@/assets/img/offlineJoin/p02/jmstd03.png">
            </div>
            <div class="flexColumnCenter02">
              <img class="sty001" src="@/assets/img/offlineJoin/p02/jmstd02.png">
            </div>
            <div class="flexColumnCenter02">
              <img class="sty001" src="@/assets/img/offlineJoin/p02/jmstd01.png">
            </div>
          </div>
        </div>
        <div class="flex02">
          <img class="sty002" src="@/assets/img/offlineJoin/p02/jmfc.png">
          <div class="flexRowAuto02">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc001.png">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc002.png">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc003.png">
          </div>
          <div class="flexRowAuto02">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc004.png">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc005.png">
            <img class="sty003" src="@/assets/img/offlineJoin/p02/jmfc006.png">
          </div>
        </div>
        <div class="flex02">
          <img class="sty004" src="@/assets/img/offlineJoin/p02/jmlc00.png">
          <div class="flexRowAuto02">
            <img class="sty005" src="@/assets/img/offlineJoin/p02/jmlc001.png">
          </div>
        </div>
        <div class="flex02">
          <img class="sty006" src="@/assets/img/offlineJoin/p02/bfmdzs00.png">
          <div class="flexRowAuto02">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs01.png">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs02.png">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs03.png">
          </div>
          <div class="flexRowAuto02">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs04.png">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs05.png">
            <img class="sty007" src="@/assets/img/offlineJoin/p02/bfmdzs06.png">
          </div>
        </div>
        <div class="flex02">
          <img class="sty008" src="@/assets/img/offlineJoin/p02/bfcpzs00.png">
          <div class="flexRowAuto02">
            <img class="sty009" src="@/assets/img/offlineJoin/p02/bfcpzs01.png">
          </div>
        </div>
        <div class="flex03">
          <img class="sty010" src="@/assets/img/offlineJoin/p02/lxwm.png">
          <div class="flexRowAuto03">
            <div class="dxjs">
              <div class="QRCodeSizeT" style="align-self: flex-start;">
                <img src="@/assets/img/tel.png">
                <span>187-2095-4280</span>
              </div>
              <div class="sty011">ADDRESS - 公司地址<span class="hrCls"></span></div>
              <div class="sty012">江西省南昌市红谷滩新区博能金融中心8楼、9楼、10楼</div>
              <img class="sty013" src="@/assets/img/offlineJoin/p02/jmQRcode.png">
            </div>
          </div>
        </div>
        <img class="HST_lxwm" src="@/assets/img/offlineJoin/lxwm.png" @click="jumpdown02()">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showDlCode: true
    }
  },
  created() {},
  watch: {
    showDlCode() {
      if(!this.showDlCode){
        setTimeout(()=> {
          this.showDlCode = true;
        },3000)
      }
    }
  },
  methods: {
    jumpdown02() {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
@keyframes example1 {
  0% {
    opacity: 0;
  }
  100% {
     opacity: 1;
  }
}
@keyframes example2 {
  0% {
    opacity: 1;
  }
  100% {
     opacity: 0;
  }
}
.dm1 {
      animation-name: example1;
      animation-duration: 0.5s;
}
.dm2 {
      animation-name: example2;
      animation-duration: 0.5s;
      opacity: 0;
}
.offlineJoinDIV {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .dlCode {
    position: fixed;
    right: 30px;
    text-align: center;
    color: #333;
    top: 45%;
    display: flex;
    align-items: center;
    height: 200px;
    > div {
      padding: 20px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .img1 {
      width: 100px;
      margin: 10px 0;
    }
    .img2 {
      width: 60px;
      margin-left: 20px;
    }
    .img3 {
      transform: rotate(180deg);
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .offlineJoinContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .topImgBox {
      // width: 1920px;
      width: 100%;
      height: 800px;
      background: url('../assets/img/offlineJoin/jm01.png');
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logoWCC {
        margin-top: 43px;
        width: 196px;
        height: 84px;
      }
      .flexClumAlignC {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1200px;
        margin-top: 110px;
        .txt01 {
          font-size: 70px;
          font-family: Light;
          color: #ffffff;
        }
        .txt02 {
          margin-top: 78px;
          font-size: 57px;
          font-family: Light;
          font-weight: 300;
          color: #ffffff;
        }
        .txt03 {
          font-size: 24px;
          font-family: Light;
          font-weight: 200;
          color: #ffffff;
        }
      }
      img {
        margin-top: 284px;
        width: 200px;
        height: 84px;
      }
      .EnglishT {
        margin-top: 120px;
        font-size: 29px;
        font-family: Regular;
        font-weight: 100;
        color: #FFFFFF;
      }
      .ChineseT {
        font-size: 87px;
        font-family: Light;
        font-weight: normal;
        color: #FFFFFF;
      }
    }
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      .flex01 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .w422h78 {
          margin-top: 80px;
          width: 422px;
          height: 78px;
        }
        .flexColumnCenter {
          width: 1200px;
          display: flex;
          // flex-direction: column;
          justify-content: space-around;
          .dxjs {
            // width: 562px;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .imgTop {
              margin-bottom: 42px;
              width: 562px;
              height: 300px;
            }
            .imgText {
              width: 390px;
              height: 40px;
            }
          }
        }
      }
      .flex02 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .w440h150 {
          margin-top: 80px;
          width: 440px;
          height: 150px;
        }
        .w942h210 {
          margin-top: 120px;
          width: 942px;
          height: 210px;
        }
        .flexRowAuto {
          margin-top: 20px;
          width: 1200px;
          height: 440px;
          display: flex;
          // flex-wrap: wrap;
          justify-content: space-around;
          .flexColumnCenter02 {
            width: 284px;
            // height: 284px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 284px;
              height: 284px;
            }
            .linkNN {
              margin-top: 24px;
              font-size: 33px;
              font-family: Light;
              font-weight: normal;
              color: #323232;
            }
          }
          .imgW180H180 {
            width: 180px;
            height: 180px;
          }
        }
        .flexRowAuto02 {
          margin-top: 50px;
          width: 1200px;
          // height: 400px;
          display: flex;
          // flex-wrap: wrap;
          justify-content: space-around;
          .imgW180H180 {
            width: 284px;
            height: 284px;
          }
        }
      }
      .flex03 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .w456h150 {
          margin-top: 80px;
          width: 456px;
          height: 150px;
        }
        .flexRowAuto03 {
          margin-top: 50px;
          width: 1300px;
          // height: 320px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .dxjs {
            display: flex;
            flex-direction: column;
            align-items: center;
            .QRCodeSize {
              margin-top: 20px;
              // margin-bottom: 20px;
              width: 560px;
              height: 40px;
            }
            .QRCodeSizeT {
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
              }
              span {
                font-size: 40px;
                font-family: Swis721 BT;
                color: #05aa8c;
              }
            }
            .marginTop12 {
              margin-top: 12px;
              margin-bottom: 30px;
              width: 214px;
              height: 214px;
            }
          }
        }
      }
      .HST_lxwm {
        align-self: center;
        margin-top: 80px;
        margin-bottom: 10px;
        width: 626px;
        height: 156px;
      }
    }
  }
}
.sty001 {
  width:372px;height:372px;
}
.sty002 {
  margin-top: 80px;
  width:293px;
  height:85px;
}
.sty003 {
  width:365px;height:278px;margin: 0 20px;
}
.sty004 {
  margin-top: 120px;
  width:305px;height:89px;
}
.sty005 {
  width:1900px;height:726px;
}
.sty006 {
  margin-top: 120px;
  width:429px;height:85px;
}
.sty007 {
  width:359px;height:274px;margin:0 10px;
}
.sty008 {
  margin-top: 120px;
  width:429px;height:85px;
}
.sty009 {
  width:1900px;height:656px;
}
.sty010 {
  margin-top: 120px;
  width:625px;height:144px;
}
.sty011 {
  display: flex;
  margin-bottom: 5px;
  width:542px;height:14px;line-height:14px;font-size: 14px;font-family: Light;
  .hrCls {
    flex-grow: 1;
    height: 7px;
    display: inline-block;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
  }
}
.sty012 {
  width:542px;height:22px;line-height:22px;font-size: 22px;font-family: Light;
}
.sty013 {
  margin-top: 12px;
  margin-bottom: 30px;
  width:382px;
  height:382px;
}
</style>
