<template>
  <div class="indexBC">
    <!-- <div class="bannerBoxCC" @click="goDetail(bannerProductId)"></div> -->
    <!-- <div class="bannerBoxCC" @click="goLinkPage(item.link_url)"></div> -->
    <swiper class="swiper-container swiperBox" ref="adSwiper" :options="swiperOption">
      <swiper-slide class="swImgSC" v-for="(item, index) in ADListA" :key="index+'sc'">
        <div class="bannerBoxCC" :style="{'background':'url('+item.logo_img+') no-repeat','background-size': '100% 100%'}" @click="goDetail(item.link_url)"></div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>

    <span class="tilCla01">助力科学健康护肤</span>
    <span class="tilCla02">Contribute to scientific and healthy skin care</span>
    <div class="WOMFirBxCC">
      <img src="@/assets/img/01.jpg"  @click="goToRouter('brandMsgList')"/>
      <img src="@/assets/img/02.jpg"  @click="goToRouter('brandMsgList')"/>
      <img src="@/assets/img/03.jpg"  @click="goToRouter('brandMsgList')"/>
      <img src="@/assets/img/04.jpg" @click="goDetail(42)"/>

      <!-- <img src="@/assets/img/WOM_gcj.png" />
      <img src="@/assets/img/WOM_lxr.png" />
      <img src="@/assets/img/WOM_hsy.png" />
      <img src="@/assets/img/WOM_rze.png" />
      <img src="@/assets/img/WOM_aaa.png" />
      <img src="@/assets/img/WOM_bbb.png" /> -->
    </div>
    <span class="tilCla01">心动爆品 挚爱甄选</span>
    <span class="tilCla02">HEART EXPLOSIVE PRODUCTS</span>
    <div class="prdListBC">
      <!-- 热门主推产品 -->
      <div class="prdListItmBC" v-for="(item,index) in indexProductListC" :key="index+'ipd'" :style="{'flex-direction':(index%2==0?'row-reverse':'row')}" @click="goDetail(item.product_id)">
        <img class="prdImgBC" :src="item.pc_logo_img" />
        <div class="hotBXFLXBC">
          <span class="prdInBC01">{{item.effect_cn}}</span>
          <span class="prdInBC02">{{item.component}}</span>
          <img class="prdImgBtnC" src="@/assets/img/goDetailBtn01.png" />
        </div>
      </div>
    </div>
    <span class="tilCla01">更多爆款 随心挑选</span>
    <span class="tilCla02">MORE HOT STYLE TO PICK</span>
    <div class="stpListItmBC" v-for="(item, index) in productListC" :key="index + 'lc'">
      <div class="stepBannerBC" @click="targetOpenStp(index)" :style="`background:url(${item.category_img}) no-repeat`">
        <i v-show="item.stepFlg == -1" class="el-icon-arrow-right marginLeft50"></i>
        <i v-show="item.stepFlg == 1" class="el-icon-arrow-down marginLeft50"></i>
      </div>
      <div v-show="item.stepFlg == 1" class="stpListImBC">
        <div class="stpItmCC" v-for="(temp, index) in item.product" :key="index + 'stb'" @click="goDetail(temp.product_id)">
          <img class="prdLImgC" :src="temp.index_img">
          <span class="prdIIDTxtC">{{temp.product_name}}</span>
          <!-- <div class="womItmBtnC">点击查看 ></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList, getIndexPrdList, getIndexADImgList } from "../util/api"
export default {
  name: 'index',
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        spaceBetween: 30,
        speed: 1000, // 滑动速度
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // autoplay: false,
        loop: true
      },
      openSetTmFlag: false,
      STFlag: 0,
      productListC: [],
      indexProductListC: [],
      ADListA: []
    }
  },
  computed: {
    swiper() {
      return this.$refs.adSwiper.swiper
    }
  },
  created() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      getIndexPrdList({}).then((res)=>{
        this.indexProductListC = res.data.product_list;
        this.bannerProductId = res.data.banner_product_id;
      })
      getIndexADImgList(1).then((res)=>{
        this.ADListA = res.data.ad_list
      })
      getProductList({}).then((res)=>{
        this.productListC = res.data;
        this.productListC.forEach((iem)=>{
          iem.stepFlg = 1;
        })
      })
    },
    goDetail(val) {
      console.log(typeof(val))
      if(val == '') {
        // val 不是产品ID，也不是链接，则什么都不做
      } else if((val + '').indexOf('http') >= 0) {
        window.open(val)
      } else {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.$router.push({ path: '/productIntroduction', query: { pId: val }});
      }
    },
    goToRouter(val) {
      // sessionStorage.setItem("lastLink", val);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push("/" + val);
    },
    targetOpenStp(val) {
      let tmpList = JSON.parse(JSON.stringify(this.productListC))
      tmpList[val].stepFlg = -tmpList[val].stepFlg
      this.productListC = tmpList;
    },
    goLinkPage(lnk) {
      window.open(lnk)
    },
    goToIntro() {
      alert("产品研发中，敬请期待...");
    },
    goToProduct() {
      window.open("https://www.snefe.com/website/detailproduct/42")

    }
  }
}
</script>

<style lang="less" scoped>
  @import "../assets/font/font.css";
.indexBC {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  .swiperBox {
    // margin-bottom: 20px;
    // width: 750px;
    // height: 1332px;
    width: 100%;
    display: flex;
    justify-content: center;
    .swiper-button-prev, .swiper-button-next {
      // background: #f00;
      margin-top: 0;
      top: 0;
      width: 60px;
      height: 100%;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-prev:focus {
      outline: none !important;
      -webkit-outline: none !important;
    }
    .swiper-button-next:focus {
      outline: none !important;
      -webkit-outline: none !important;
    }
    .swiper-button-next {
      right: 0;
    }
    .swImgSC {
      width: 100%;
      // width: 750px;
      // height: 1332px;
      .bannerBoxCC {
        width: 100%;
        // width: 1900px;
        height: 800px;
        // background: url("../assets/img/imdexImg.png");
        background-size: 100% 100%;
        cursor: pointer;
        .logoWCC {
          margin-left: 66px;
          margin-top: 43px;
          width: 130px;
          height: 56px;
        }
        .flexClumAlignC {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 412px;
          margin-top: 110px;
          margin-left: 270px;
          .txt01 {
            font-size: 78px;
            font-family: Light;
            color: #ffffff;
          }
          .txt02 {
            margin-top: 78px;
            font-size: 57px;
            font-family: Light;
            font-weight: 300;
            color: #ffffff;
          }
          .txt03 {
            font-size: 18px;
            font-family: Light;
            font-weight: 200;
            color: #ffffff;
          }
          .btn001 {
            margin-top: 42px;
            display: inline-block;
            width: 216px;
            height: 62px;
            color: #fff;
            font-size: 28px;
            font-family: Light;
            border: 2px solid #fff;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .WOMFirBxCC {
    width: 1206px;
    // height: 342px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    cursor: pointer;
    img {
      width: 592px;
      height: 293px;
      margin: 12px 0;
      border-radius: 6px;
    }
  }
  .prdListBC {
    width: 1210px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .prdListItmBC {
      display: flex;
      align-items: center;
      margin-top: 38px;
      cursor: pointer;
      .prdImgBC {
        width: 606px;
        height: 374px;
      }
      .hotBXFLXBC {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        .prdInBC01 {
          width: 570px;
          text-align: center;
          margin-top: 56px;
          margin-bottom: 18px;
          font-size: 30px;
          font-family: Light;
          color: #323232;
        }
        .prdInBC02 {
          width: 570px;
          text-align: center;
          font-size: 18px;
          font-family: Light;
          color: #323232;
        }
        .prdImgBtnC {
          margin-top: 38px;
          margin-bottom: 80px;
          width: 224px;
          height: 42px;
        }
      }
    }
  }
  .stpListItmBC {
    .stepBannerBC {
      margin: 20px auto;
      border-radius: 20px;
      width: 1200px;
      height: 280px;
      // background: url("../assets/img/stepBanner.png");
      background-size: 100% 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;
      .marginLeft50 {
        margin-top: 90px;
        margin-right: 40px;
        font-size: 40px;
        font-weight: 700;
      }
    }
    .stpListImBC {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      .stpItmCC {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        // margin-top: 10px;
        .prdLImgC {
          border-radius: 6px;
          width: 230px;
          height: 272px;
        }
        .prdIIDTxtC {
          margin: 20px auto;
          width: 230px;
          text-align: center;
          font-size: 20px;
          font-family: Light;
          // font-weight: normal;
          color: #323232;
          // line-height: 43px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .womItmBtnC {
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #05aa8c;
          color: #fff;
          font-size: 20px;
          width: 162px;
          height: 44px;
          line-height: 42px;
          border-radius: 22px;
        }
      }
    }
  }
  .stpListItmBC:last-child {
    margin-bottom: 40px;
  }
  .tilCla01 {
    margin-top: 70px;
    margin-bottom: 27px;
    font-size: 70px;
    font-family: Light;
    color: #323232;
  }
  .tilCla02 {
    margin-bottom: 44px;
    font-size: 26px;
    font-family: Regular;
    font-weight: 100;
    color: #05aa8c;
  }
}
</style>
<style lang="less">
// .swiper-pagination {
//   background-color: #f00;
// }
.swiper-pagination-bullet {
  width: 60px !important;
  height: 6px !important;
  border-radius: 0 !important;
  background: #fff !important;
  opacity: .5 !important;
}
.swiper-pagination-bullet-active {
  background: #fff !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet:focus {
  outline: none !important;
  -webkit-outline: none !important;
}
</style>
