<template>
  <div class="msgDtlDIV">
    <div class="dateBxC">
      <span class="dateDayCC">{{newsObj.issue_date.slice(-2)}}</span>
      <span class="dateMonthCC">{{new Date(newsObj.issue_date).getFullYear()}}年{{new Date(newsObj.issue_date).getMonth()+1}}月</span>
    </div>
    <div class="offlineJoinContent">
      <p class="titleBC">{{newsObj.title}}</p>
      <div class="flexColumnStart" v-html="newsObj.content"></div>
      <div class="nextArticleCC">
        <span class="pieFix" v-if="newsObj.prev.news_id" @click="goNext(newsObj.prev.news_id)">上一篇：{{newsObj.prev.title}}</span>
        <span class="pieFix" v-if="newsObj.next.news_id" @click="goNext(newsObj.next.news_id)">下一篇：{{newsObj.next.title}}</span>
        <span class="pieFix" v-if="!newsObj.prev.news_id">上一篇：没有了</span>
        <span class="pieFix" v-if="!newsObj.next.news_id">下一篇：没有了</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsDetail } from "../util/api"
export default {
  name: 'index',
  data() {
    return {
      newsObj: {}
    }
  },
  watch: {
    '$route' (val, value) {
      if(val !== value) {
        this.init()
      }
    }
  },
  created() {
   this.init()
  },
  methods: {
    init () {
      getNewsDetail(this.$route.query.nId).then((res)=>{
        this.newsObj = res.data
      })
    },
    goNext(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      let newRouter = JSON.parse(JSON.stringify(this.$route.query))
      newRouter.nId = val;
      this.$router.replace({
        query: newRouter
      })
    },
  }
}
</script>
<style lang="less" scoped>
.msgDtlDIV {
  display: flex;
  // flex-direction: column;
  // align-items: center;
  justify-content: center;
  .dateBxC {
    width: 100px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #000;
    margin-top: 80px;
    margin-right: 30px;
    .dateDayCC {
      font-size: 64px;
      font-family: Swis721 BT;
      color: #323232;
    }
    .dateMonthCC {
      font-size: 18px;
      font-family: Swis721 BT;
      color: #323232;
    }
  }
  .offlineJoinContent {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .titleBC {
      width: 1200px;
      font-size: 32px;
      font-family: Medium;
      color: #323232;
      text-align: center;
    }
    .issueDteBC {
      width: 1200px;
      font-size: 26px;
      font-family: Swis721 Lt BT;
      font-weight: 300;
      color: #323232;
      opacity: 0.7;
      text-align: center;
    }
    .flexColumnStart {
      width: 1200px;
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
    }
    .nextArticleCC {
      margin: 100px auto;
      width: 1300px;
      display: flex;
      justify-content: space-between;
      .pieFix {
        // align-self: flex-start;
        width: 500px;
        font-size: 26px;
        font-weight: 600;
        font-family: Medium;
        color: #323232;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
</style>
