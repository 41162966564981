<template>
  <div class="historyBC">
    <div class="offlineJoinContentHisBBC">
      <div class="topImgBox">
        <img src="@/assets/img/history/ljts.png" @click="jumpdown01(99999999)" />
      </div>
      <div class="flexColumnStart">
        <img src="@/assets/img/history/001.jpg" />
        <img src="@/assets/img/history/002.jpg" />
        <img src="@/assets/img/history/003.jpg" />
        <img src="@/assets/img/history/004.jpg" />
        <div class="w1200flex">
          <div class="disFlexClm">
            <img class="w70h70mg20auto" src="@/assets/img/history/005-1.jpg" />
            <span class="wordCLsDDD">安全无伤害</span>
          </div>
          <div class="disFlexClm">
            <img class="w70h70mg20auto" src="@/assets/img/history/005-2.jpg" />
            <span class="wordCLsDDD">天然无污染</span>
          </div>
          <div class="disFlexClm">
            <img class="w70h70mg20auto" src="@/assets/img/history/005-3.jpg" />
            <span class="wordCLsDDD">有效无负担</span>
          </div>
        </div>
        <p><span class="span01">明星单品</span><span class="span02">STAR ITEM</span></p>
        <p class="stepSpanCls">黑金系列</p>
        <img src="@/assets/img/history/006.jpg" />
        <img src="@/assets/img/history/007.jpg" />
        <p class="stepSpanCls">洁面系列</p>
        <img src="@/assets/img/history/008.jpg" />
        <img src="@/assets/img/history/009.jpg" />
        <p><span class="span01">科研实力</span><span class="span02">SCIENTIFIC RESEARCH STRENGTH</span></p>
        <img src="@/assets/img/history/010.jpg" />
        <img src="@/assets/img/history/011.jpg" />
        <p><span class="span01">品牌技术</span><span class="span02">BRAND TECHNOLOGY</span></p>
        <img src="@/assets/img/history/012.jpg" />
        <p><span class="span01">明星体验官</span><span class="span02">STAR EXPERIENCE OFFICER</span></p>
        <img src="@/assets/img/history/013.jpg" />
        <p><span class="span01">发展历程</span><span class="span02">DEVELOPMENT</span></p>
        <img src="@/assets/img/history/014.jpg" />
        <img src="@/assets/img/history/015.jpg" />
        <img src="@/assets/img/history/016.jpg" />
        <img src="@/assets/img/history/017.jpg" />
        <img src="@/assets/img/history/018.jpg" />
        <p><span class="span01">品牌荣誉</span><span class="span02">BRAND HONORS</span></p>
        <img src="@/assets/img/history/019.jpg" />
        <img src="@/assets/img/history/020.jpg" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    jumpdown01(val) {
      document.body.scrollTop = document.documentElement.scrollTop = val
    }
  }
}
</script>
<style lang="less" scoped>
.historyBC {
  .offlineJoinContentHisBBC {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    .topImgBox {
      // width: 1920px;
      width: 100%;
      height: 800px;
      background: url("../assets/img/history/banner.jpg");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-top: 450px;
        margin-left: -810px;
        width: 372px;
        height: 92px;
      }
    }
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      p {
        margin-top: 50px;
        .span01 {
          // width: 200px;
          // height: 50px;
          font-size: 54px;
          /*font-family: HYQiHeiX1;*/
          font-family: Medium;
          // font-weight: 600;
          color: #323232;
          // line-height: 444px;
        }
        .span02 {
          // width: 307px;
          // height: 34px;
          margin-left: 20px;
          font-size: 44px;
          /*font-family: Swis721 Th BT;*/
          font-family: Regular;
          font-weight: 100;
          color: #05aa8c;
          // line-height: 292px;
          opacity: 0.35;
        }
      }
      img {
        margin: 40px auto;
        width: 1200px;
      }
    }
  }
}
.w1200flex {
  width: 1200px;
  display: flex;
}
.disFlexClm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
}
.w70h70mg20auto {
  width: 70px !important;
  height: 70px !important;
  margin: 20px auto !important;
}
.stepSpanCls {
  font-size: 36px;
  font-weight: 500;
  color: #05aa8c;
}
.wordCLsDDD {
  color: #05aa8c;
}
</style>
