<template>
  <div id="app">
    <topBar />
    <router-view />
    <btmBar />
  </div>
</template>

<script>
import topBar from "./components/topBar.vue"
import btmBar from "./components/btmBar.vue"
export default {
  components: {
    topBar,
    btmBar
  },
  mounted () {
    this.isMobile()
  },
  methods: {
    // 添加判断方法
    isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        console.log('移动端打开')
        
        let s1 = window.location.href
        // let s1i = s1.indexOf('#')
        let s1i = s1.lastIndexOf('/') + 1
        let subs = s1.slice(s1i)
        // let url_mobile = 'http://192.168.10.222/m/#/' + subs // 测试环境
        let url_mobile = 'https://www.snefe.com/m/#/' + subs // 测试环境
        window.location.href = url_mobile
      } else {
        console.log("PC端打开")
      }
    }
  }
}
</script>
