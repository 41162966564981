import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import history from '../views/history.vue'
import joinUs from '../views/joinUs.vue'
import offlineJoin from '../views/offlineJoin.vue'
import antiForgeryCheck from '../views/antiForgeryCheck.vue'
import brandMsgList from '../views/brandMsgList.vue'
import brandMsgDetail from '../views/brandMsgDetail.vue'
import productOverview from '../views/productOverview.vue'
import productIntroduction from '../views/productIntroduction.vue'
import productDetail from '../views/productDetail.vue'
import WOMStarDetail from '../views/WOMStarDetail.vue'
import WOMStarList from '../views/WOMStarList.vue'
import QRcodeOfOfficialAccount from '../views/QRcodeOfOfficialAccount.vue'
import contactUs from '../views/contactUs.vue'

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/history',
    name: 'history',
    component: history
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: joinUs
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs
  },
  {
    path: '/QRcodeOfOfficialAccount',
    name: 'QRcodeOfOfficialAccount',
    component: QRcodeOfOfficialAccount
  },
  {
    path: '/productOverview',
    name: 'productOverview',
    component: productOverview
  },
  {
    path: '/offlineJoin',
    name: 'offlineJoin',
    component: offlineJoin
  },
  {
    path: '/antiForgeryCheck',
    name: 'antiForgeryCheck',
    component: antiForgeryCheck
  },
  {
    path: '/WOMStarList',
    name: 'WOMStarList',
    component: WOMStarList
  },
  {
    path: '/WOMStarDetail',
    name: 'WOMStarDetail',
    component: WOMStarDetail
  },
  {
    path: '/brandMsgList',
    name: 'brandMsgList',
    component: brandMsgList
  },
  {
    path: '/brandMsgDetail',
    name: 'brandMsgDetail',
    component: brandMsgDetail
  },
  {
    path: '/productIntroduction',
    name: 'productIntroduction',
    component: productIntroduction
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: productDetail
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: '/',
  routes
})

export default router
