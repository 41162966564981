<template>
  <div class="brandMsgBC">
    <img class="titleImgBC" src="@/assets/img/PITitle.png" />
    <div class="msgSynopsis">
      <el-select class="selectBoxC" v-model="selectValue" placeholder="请选择" @change="getMSGListByTY()">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div class="msgItmBC" v-for="(item, index) in newsListC" :key="index+'nl'" @click="goDetail(item.news_id)">
        <div class="dateBxC">
          <span class="dateDayCC">{{item.issue_date.slice(-2)}}</span>
          <span class="dateMonthCC">{{new Date(item.issue_date).getFullYear()}}年{{new Date(item.issue_date).getMonth()+1}}月</span>
        </div>
        <img :src="item.logo_img" />
        <div class="msgConBBC">
          <span class="msgTitleCC">{{item.title}}</span>
          <span class="msgContnCC">{{item.brief}}</span>
          <span class="btnToDtlC" @click="goDetail(item.news_id)">阅读全文</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsList } from "../util/api"
export default {
  data () {
    return {
      options: [{
        value: '0',
        label: '全部新闻'
      }],
      selectValue: '0',
      newsListC: []
    }
  },
  created () {
    // 年份提取
    let preYear = 2020
    let currentYear = new Date().getFullYear()
    for (let i = currentYear; i >= preYear; i--) {
      let item = {
        'value': '' + i,
        'label': '' + i
      }
      this.options.push(item)
    }

    getNewsList({}).then((res)=>{
      this.newsListC = res.data.news_list;
    })
  },
  methods: {
    goDetail(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({ path: '/brandMsgDetail', query: { nId: val }});
    },
    getMSGListByTY () {
      getNewsList('year/'+this.selectValue).then((res)=>{
        this.newsListC = res.data.news_list;
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/font/font.css";
.brandMsgBC {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  .titleImgBC {
    margin-top: 80px;
    margin-bottom: 40px;
    width: 332px;
    height: 136px;
  }
  .msgItmBC {
    display: flex;
    border-bottom: 2px solid #000;
    margin: 60px auto;
    cursor: pointer;
    .dateBxC {
      width: 160px;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #000;
      .dateDayCC {
        font-size: 64px;
        font-family: Swis721 BT;
        color: #323232;
      }
      .dateMonthCC {
        font-size: 24px;
        font-family: Swis721 BT;
        color: #323232;
      }
    }
    img {
      margin-bottom: 60px;
      margin-left: 50px;
      margin-right: 40px;
      max-width: 480px;
      height: 200px;
    }
    .msgConBBC {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      max-width: 600px;
      .msgTitleCC {
        margin-top: 20px;
        font-size: 22px;
        font-family: Light;
        color: #323232;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .msgContnCC {
        margin-top: 38px;
        margin-bottom: 48px;
        font-size: 18px;
        font-family: Medium;
        color: #808080;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .btnToDtlC {
        font-size: 18px;
        font-family: Medium;
        text-decoration: underline;
        color: #323232;
        cursor: pointer;
      }
    }
  }
  .msgItmBC:last-child {
    border-bottom: 0;
  }
}
</style>
<style lang="less">
.el-select {
  align-self: flex-start;
  width: 200px;
  height: 50px;
}
.el-select .el-input .el-select__caret {
  color: #fff !important;
  font-size: 26px !important;
  margin-right: 12px !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #0ea9bb !important;
}
.el-select-dropdown__item.selected {
  color: #fff !important;
  background-color: #0ea9bb !important;
  font-weight: normal !important;
}
.el-select-dropdown__wrap {
  max-height: 320px !important;
}
.el-select-dropdown__item {
  height: 62px !important;
  line-height: 62px !important;
  font-size: 28px !important;
  font-family: Medium !important;
}
.el-input__icon {
  line-height: 56px !important;
}
.msgSynopsis {
  width: 1200px;
  // align-self: flex-start;
  .selectBoxC {
    // margin-bottom: 40px;
    width: 230px;
    height: 60px;
    .el-input {
      width: 220px;
      input {
        color: #fff !important;
        background-color: #05aa8c !important;
        width: 230px;
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        font-family: Medium;
      }
    }
  }
}
</style>
