<template>
  <div class="QRDivBC">
    <div class="topImgBox">
      <img src="@/assets/img/QRcodeOfOfficialAccount.png">
      <span>扫描微信公众号二维码</span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {}
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
.QRDivBC {
  background: #fff;
  .topImgBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 650px;
    img {
      width: 358px;
      height: 358px;
    }
    span {
      font-size: 26px;
      font-family: Light;
      color: #323232;
    }
  }
}
</style>
