<template>
  <div class="offlineJoinDIVPDI">
    <div class="offlineJoinContentPDI">
      <div class="flexColumnStart">
        <div class="titleBoxC">
          <div class="zwTextB">{{productObj.effect_cn}}</div>
          <div class="egTextB">{{productObj.effect_en.toUpperCase()}}</div>
        </div>
        <div class="imgsBox">
          <swiper class="swiper-container swiperBox" ref="mySwiper" :options="swiperOption">
            <swiper-slide class="swImgSC" v-for="(item, index) in productObj.image" :key="index+'sc'"><img :src="item"></swiper-slide>
          </swiper>
          <div class="imgListBx">
            <div class="pdimgBoxCC" v-for="(item, index) in productObj.image" :key="index+'sc'" @click="swiper.slideTo(index);" :style="{'background':'url('+item+') no-repeat center center','background-size':'100% auto'}"></div>
          </div>
        </div>
        <span class="pdNameSty">{{productObj.product_name}}</span>
        <div class="womItmBtnC" @click="goDetail(productObj.product_id)">点击查看 ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProductDetail } from "../util/api"
export default {
  name: 'index',
  data () {
    return {
      swiperOption: {
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true // 允许点击小圆点跳转
        // },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        direction: 'vertical',
        autoplay: false,
        loop: false
      },
      productObj: {
        effect_en: ''
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  created() {
    getProductDetail(this.$route.query.pId).then((res)=>{
      this.productObj = res.data
    })
  },
  methods: {
    goDetail(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({ path: '/productDetail', query: { pId: val }});
    },
  }
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
.offlineJoinDIVPDI {
  display: flex;
  flex-direction: column;
  align-items: center;
  .offlineJoinContentPDI {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flexColumnStart {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      .titleBoxC {
        width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
        .zwTextB {
          text-align: center;
          font-size: 42px;
          font-family: Light;
          color: #323232;
        }
        .egTextB {
          font-size: 14px;
          font-family: Swis721 Lt BT;
          font-weight: 300;
          color: #323232;
        }
      }
      .imgsBox {
        display: flex;
        align-items: center;
        .swiperBox {
          width: 580px;
          height: 750px;
          .swImgSC {
            width: 580px;
            height: 750px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              border-radius: 16px;
              width: 580px;
              height: 750px;
            }
          }
        }
        .imgListBx {
          width: 260px;
          height: 750px;
          margin-left: 16px;
          display: flex;
          justify-content: space-between;
          // align-self: center;
          flex-direction: column;
          .pdimgBoxCC {
            width: 260px;
            height: 240px;
            border-radius: 12px;
          }
        }
      }
      .pdNameSty {
        width: 1000px;
        margin-top: 54px;
        margin-bottom: 18px;
        text-align: center;
        font-size: 36px;
        font-family: Light;
        color: #323232;
      }
      .womItmBtnC {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #05aa8c;
        color: #fff;
        font-size: 20px;
        width: 162px;
        height: 44px;
        line-height: 42px;
        border-radius: 22px;
        cursor: pointer;
      }
    }
  }
}
</style>
