<template>
  <div class="indexBC">
    <span class="tilCla01">全线系列</span>
    <span class="tilCla02">THE FULL SERIES</span>
    <div class="stpListItmBC" v-for="(item, index) in productListC" :key="index + 'lc'">
      <div class="stepBannerBC" @click="targetOpenStp(index)" :style="`background:url(${item.category_img}) no-repeat`">
        <i v-show="item.stepFlg == -1" class="el-icon-arrow-right marginLeft50"></i>
        <i v-show="item.stepFlg == 1" class="el-icon-arrow-down marginLeft50"></i>
      </div>
      <div v-show="item.stepFlg == 1" class="stpListImBC">
        <div class="stpItmCC" v-for="(temp, index) in item.product" :key="index + 'stb'" @click="goDetail(temp.product_id)">
          <img class="prdLImgC" :src="temp.core_img">
          <span class="prdIIDTxtC">{{temp.product_name}}</span>
          <div class="womItmBtnC">点击查看 ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProductList } from "../util/api"
export default {
  name: 'index',
  data() {
    return {
      productListC: [],
    }
  },
  watch: {},
  created() {
    // this.$store.state.pdstepN = 0;
    getProductList({}).then((res)=>{
      this.productListC = res.data;
      this.productListC.forEach((iem)=>{
      // 放开后，点击脚部某系列跳转到本页打开该系列....别删就对了！！
      // this.productListC.forEach((iem, index)=>{
        // if(this.$store.state.pdstepN == index) {
        //   iem.stepFlg = 1;
        // } else {
          iem.stepFlg = 1;
        // }
      })
    })
  },
  methods: {
    goDetail(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({ path: '/productIntroduction', query: { pId: val }});
    },
    targetOpenStp(val) {
      let tmpList = JSON.parse(JSON.stringify(this.productListC))
      tmpList[val].stepFlg = -tmpList[val].stepFlg
      this.productListC = tmpList;
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../assets/font/font.css";
.indexBC {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  .stpListItmBC {
    .stepBannerBC {
      margin: 30px auto;
      border-radius: 20px;
      width: 1200px;
      height: 280px;
      // background: url("../assets/img/stepBanner.png");
      background-size: 100% 100% !important;
      // background-size: 100% auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .marginLeft50 {
        margin-top: 90px;
        margin-right: 40px;
        font-size: 40px;
        font-weight: 700;
      }
    }
    .stpListImBC {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      .stpItmCC {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        margin-top: 20px;
        cursor: pointer;
        .prdLImgC {
          border-radius: 6px;
          width: 230px;
          height: 272px;
        }
        .prdIIDTxtC {
          margin: 30px auto;
          width: 230px;
          text-align: center;
          // height: 20px;
          font-size: 20px;
          font-family: Light;
          // font-weight: normal;
          color: #323232;
          // line-height: 43px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .womItmBtnC {
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #05aa8c;
          color: #fff;
          font-size: 20px;
          width: 162px;
          height: 44px;
          line-height: 42px;
          border-radius: 22px;
        }
      }
    }
  }
  .stpListItmBC:last-child {
    margin-bottom: 40px;
  }
  .tilCla01 {
    margin-top: 80px;
    margin-bottom: 0px;
    font-size: 46px;
    font-family: Light;
    font-weight: normal;
    color: #323232;
  }
  .tilCla02 {
    margin-bottom: 30px;
    font-size: 20px;
    font-family: Regular;
    font-weight: 100;
    color: #05aa8c;
  }
}
</style>
