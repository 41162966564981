<template>
  <div class="offlineJoinDIV">
    <div class="offlineJoinContent">
      <p class="titleBC">{{praiseObj.title}}</p>
      <div class="flexColumnStart" v-html="praiseObj.content"></div>
      <!-- <p class="titleBC">送积分卡萨建档立卡经理开始的减肥临时卡记录卡就</p> -->
    </div>
  </div>
</template>
<script>
import { getWOMStarDetail } from "../util/api"
export default {
  name: 'index',
  components: {},
  data() {
    return {
      praiseObj: {}
    }
  },
  created() {
    getWOMStarDetail(this.$route.query.pId).then((res)=>{
      this.praiseObj = res.data
    })
  }
}
</script>
<style lang="less">
  @import "../assets/font/font.css";
.offlineJoinDIV {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .offlineJoinContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titleBC {
      width: 1200px;
      text-align: center;
      font-size: 32px;
      font-family: Light;
      color: #323232;
    }
    .flexColumnStart {
      // width: 1200px;
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      p {
        .edui-upload-video {
          width: 600px !important;
          height: auto !important;
        }
      }
    }
  }
}
</style>
